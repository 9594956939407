import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import theme from "../theme"

const TextContainer = styled.div`
  text-align: center;
  color: ${theme.color.white};
  padding: 0 ${theme.layout.s.gutter * 100}vw;

  @media (min-width: ${theme.breakpoint.medium}) {
    padding: 0 ${theme.layout.m.gutter * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    padding: 0 ${theme.layout.l.gutter * 100}vw;
  }

  h1 {
    margin: ${theme.layout.s.gutter * 100 * 2}vw 0;

    @media (min-width: ${theme.breakpoint.medium}) {
      margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
    }
  }

  h2,
  h3 {
    margin: ${theme.layout.s.gutter * 100 * 2}vw 0;
    text-align: ${(props) => (props.left ? "left" : "center")};

    @media (min-width: ${theme.breakpoint.medium}) {
      margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
    }
  }

  p,
  ul,
  ol {
    letter-spacing: 0.05em;
    font-weight: 300;
    margin: ${theme.layout.s.gutter * 100}vw 0;
    font-size: ${(50 / theme.layout.s.ref) * 100}vw;
    text-align: ${(props) => (props.left ? "left" : "center")};

    @media (min-width: ${theme.breakpoint.medium}) {
      margin: ${theme.layout.m.gutter * 100}vw 0;
      font-size: ${(50 / theme.layout.m.ref) * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      margin: ${theme.layout.l.gutter * 100}vw 0;
      font-size: ${(50 / theme.layout.l.ref) * 100}vw;
    }
  }

  ul {
    list-style-type: square;
    margin-left: 1.5em;
    padding: 0;
    line-height: 1.5;
  }

  a {
    color: ${theme.color.white};
    text-decoration: none;
    display: inline-block;
    border-bottom: 0.025em solid ${theme.color.white};
    line-height: 1.3;
  }

  &.large {
    h1 {
      font-size: ${(135 / theme.layout.s.ref) * 100}vw;

      @media (min-width: ${theme.breakpoint.medium}) {
        font-size: ${(135 / theme.layout.m.ref) * 100}vw;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        font-size: ${(135 / theme.layout.l.ref) * 100}vw;
      }
    }

    h2,
    h3,
    p {
      font-size: ${(90 / theme.layout.s.ref) * 100}vw;
      font-weight: 300;

      @media (min-width: ${theme.breakpoint.medium}) {
        font-size: ${(90 / theme.layout.m.ref) * 100}vw;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        font-size: ${(90 / theme.layout.l.ref) * 100}vw;
      }
    }

    p {
      margin: ${theme.layout.s.gutter * 100 * 2}vw 0;

      @media (min-width: ${theme.breakpoint.medium}) {
        margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
      }
    }
  }
`

const Text = (props) => {
  return (
    <TextContainer className={props.layout} data-bg={props.bg} {...props}>
      {props.children}
    </TextContainer>
  )
}

Text.propTypes = {
  bg: PropTypes.string,
}

Text.defaultProps = {
  siteTitle: ``,
}

export default Text
